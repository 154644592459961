export const MY_PAGE_USER_NAVIGATION = [
  {
    text: '開催予定のイベント',
    path: (): string => `/business/memberships/events`,
  },
  {
    text: 'リクエスト中のイベント',
    path: (): string => `/business/memberships/events?status=requested`,
  },
  {
    text: '過去に開催したイベント',
    path: (): string =>
      `/business/memberships/events?status=finished`,
  },
  {
    text: 'プロフィール変更',
    path: (id: number): string => `/business/memberships/users/${id}/edit`,
  },
  {
    text: 'メンバー管理',
    path: `/business/memberships/users`,
  },
  { text: 'パスワード変更', path: (id: number): string => `/business/memberships/users/${id}/password/edit` },
]
